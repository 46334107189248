<template>
  <div class="affiner">
    <h2>Make your own awesome Origamis!</h2>
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <Origamer />
  </div>
</template>

<script>
import Origamer from '@/components/Origamer.vue'

export default {
  // name: 'Affiner',
  components: {
    Origamer
  }
}
</script>
