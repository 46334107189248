<!-- todo: should probably use WebGL -->
<template>
    <button v-for="(o, i) in origamis" @click="onClick(i)">{{ o.name }}</button>
    <div v-if="idx > -1" class="affiner">
        <PolygonPlacer
            v-for="(z,i) in this.origam.zones"
            :seg_ids="z.plot_segs"
            :points="z.points"
            :shapeName="z.name"
            @my-event="(a, b, c) => this.addAffine(i, a, b, c)"
            @remove="this.affines[i] = undefined"
        />
    </div>
    <button
        class="buy_button"
        :hidden="this.affines.filter(el => {
            return el !== undefined;
        }).length < this.nZones"
        @click="this.generate"
    >Generate Recto/Verso to print!</button>
    <div>
        <canvas id="printrecto" :hidden="!generated"></canvas>
        <canvas id="printverso" :hidden="!generated"></canvas>
    </div>
    <div v-if="generated">
        <!-- todo: price as variable -->
        <p>Buy the un-blurred images to print for only 5 euros by clicking the PayPal button</p>
    </div>
    <div :hidden="!generated" id="paypal-button"></div>
</template>


<script>
import PolygonPlacer from '@/components/PolygonPlacer.vue'
import dummyshape from '@/assets/origami/dummyshape.js'
import * as cv from '@/assets/opencv/opencv.js' //maybe ok with vite?
import { markRaw } from 'vue'

export default {
    name: 'Affiner',
    components: {
        PolygonPlacer
    },
    data() {
        return {
            origam: dummyshape, // todo: could prob avoid
            origamis: [
                { file: 'testshape.js', name: 'name-1' },
                { file: 'testshape2.js', name: 'name-2' },
                { file: 'heart.js', name: 'Heart <3' },
            ],
            img0s: [],
            img1s: [],
            idx: -1, //todo: null?
            affines: [], //todo: put straight into origam, dont store here
            nZones: 10000, //todo: can keep function instead?,
            recto: null,
            verso: null,
            generated: false
        }
    },
    mounted() {
        // let recaptchaScript = document.createElement('script')
        // recaptchaScript.setAttribute('src', 'https://www.paypalobjects.com/api/checkout.js')
        // document.head.appendChild(recaptchaScript)
        var self = this
        paypal.Button.render({
            // Configure environment
            env: 'production',
            client: {
                sandbox: 'AaNFce2QxGP4_Weil7BGvKhYBPwhRaj5XN2iPlNZRxUsRQeZJ5y0M9iw0RBAyG0zW6br3My_swQUpjzd',
                production: 'AQAXw20hZyGL0CXj7Wye7hkGwl-sRJR4DH-xvdM30amPqjZomo6qz_gvpiQ5GLRjNMv60GzfRJJnfWLN'
            },
            // Customize button (optional)
            locale: 'en_US',
            style: {
                size: 'small',
                color: 'gold',
                shape: 'pill',
            },

            // Enable Pay Now checkout flow (optional)
            commit: true,

            // Set up a payment
            payment: function (data, actions) {
                return actions.payment.create({
                    transactions: [{
                        amount: {
                            // todo: variable
                            total: '5.00',
                            currency: 'EUR'
                        }
                    }]
                });
            },
            // Execute the payment
            onAuthorize: function (data, actions) {
                return actions.payment.execute().then(function () {
                    // Show a confirmation message to the buyer
                    console.log(self.recto)
                    cv.imshow('printrecto', self.recto)
                    cv.imshow('printverso', self.verso)
                    self.download('printrecto')
                    self.download('printverso')
                    window.alert('Thank you for your purchase!');
                });
            }
        }, '#paypal-button');
    },
    methods: {
        loglog() {
            console.log('hi')
        },
        // todo: helpers in script setup
        onClick(i) {
            this.idx = i
            import('@/assets/origami/' + this.origamis[i].file)
                .then(obj => {
                    this.origam = obj.default
                    // origamz = obj.default
                    this.nZones = this.origam.zones.length
                })
        },
        addAffine(i, a, b, c) {
            this.img0s[i] = b
            this.img1s[i] = c
            this.affines[i] = a
            let l = this.affines.filter(el => {
                return el !== undefined;
            }).length;
        },
        TImFolds() {
            this.affines.forEach((affine, i) => {
                let tf = this.Tf3(affine)
                tf.doublePtr(0, 1)[0] *= -1
                tf.doublePtr(1, 1)[0] *= -1
                this.origam.zones[i].T_im_fold = markRaw(tf)
            });
        },
        RealToPixels() {
            let r2p = -1;
            this.affines.forEach(affine => {
                r2p = Math.max(r2p, affine.a)
            });
            // todo: fix resolution: 1000x2000
            let paper_width = this.origam.paper.width;
            r2p = 2000 / paper_width
            return r2p
        },
        mul(A, B) {
            // todo: add check if same type or throw
            let C = new cv.Mat(A.rows, B.cols, A.type()) // no need to .delete()
            cv.gemm(A, B, 1, new cv.Mat(), 0, C)
            return C
        },
        printFace(T_print_im, T_im_fold, face, points, zoneName, imprint) {
            let src = cv.imread(zoneName)
            let dst = new cv.Mat();
            let dsize = new cv.Size(imprint.cols, imprint.rows);
            let T_printim_23 = new cv.Mat(2, 3, cv.CV_64FC1)
            for (let i = 0; i < 2; i++) {
                for (let j = 0; j < 3; j++) {
                    T_printim_23.doublePtr(i, j)[0] = T_print_im.doublePtr(i, j)[0]
                }
            }
            cv.warpAffine(src, dst, T_printim_23, dsize, cv.INTER_LINEAR, cv.BORDER_CONSTANT, new cv.Scalar());
            src.delete()
            T_printim_23.delete()
            let pt_dst = new cv.Mat(face.pts.length, 1, cv.CV_32SC2)
            face.pts.forEach((pt_id, i) => {
                let pt_fold = points[pt_id]
                let pt1_fold = new cv.Mat(3, 1, cv.CV_64F)
                pt1_fold.doublePtr(0, 0)[0] = pt_fold.x
                pt1_fold.doublePtr(1, 0)[0] = pt_fold.y
                pt1_fold.doublePtr(2, 0)[0] = 1.0
                let T_print_fold = this.mul(T_print_im, T_im_fold)
                let p_print1 = this.mul(T_print_fold, pt1_fold)
                pt_dst.intPtr(i, 0)[0] = p_print1.doublePtr(0, 0)[0]
                pt_dst.intPtr(i, 0)[1] = p_print1.doublePtr(1, 0)[0]
                pt1_fold.delete()

            });
            let pts_dst = new cv.MatVector()
            pts_dst.push_back(pt_dst)
            pt_dst.delete()
            let mask = new cv.Mat.zeros(imprint.rows, imprint.cols, cv.CV_8UC1)
            cv.fillPoly(mask, pts_dst, new cv.Scalar(1))
            pts_dst.delete()
            dst.copyTo(imprint, mask)
            mask.delete()
            dst.delete()
        },
        generate() {
            //todo: asynchronous import of opencv.js
            console.log('GENERATION: BEGIN!')

            this.img0s.forEach((img, i) => {
                var c = document.getElementById(this.origam.zones[i].name);
                var ctx = c.getContext("2d");
                ctx.drawImage(img, 0, 0);
            });
            this.TImFolds()

            let r2p = this.RealToPixels()

            if (this.recto) {
                this.recto.delete()
                this.verso.delete()
            }

            this.recto = markRaw(new cv.Mat(this.origam.paper.height * r2p, this.origam.paper.width * r2p, cv.CV_8UC4, new cv.Scalar(255, 255, 255, 255)))
            this.verso = markRaw(new cv.Mat(this.origam.paper.height * r2p, this.origam.paper.width * r2p, cv.CV_8UC4, new cv.Scalar(255, 255, 255, 255)))

            //todo wrap in funtion
            let aff_side_print = { a: 1 / r2p, r: 0, t: { x: 0, y: this.origam.paper.height } }
            let T_side_print = this.Tf3(aff_side_print)
            T_side_print.doublePtr(0, 1)[0] *= -1
            T_side_print.doublePtr(1, 1)[0] *= -1

            this.origam.zones.forEach((zone, z) => {
                zone.faces.forEach(face => {
                    let T_print_im = this.mul(this.mul(zone.T_im_fold, this.Tf3(face.Tside_fold).inv(cv.DECOMP_LU)), T_side_print).inv(cv.DECOMP_LU) //todo: dont inv at end
                    if (face.target_side === 'recto') {
                        this.printFace(T_print_im, zone.T_im_fold, face, zone.points, zone.name, this.recto)
                    } else {
                        this.printFace(T_print_im, zone.T_im_fold, face, zone.points, zone.name, this.verso)
                    }
                });
            });

            this.img1s.forEach((img, i) => {
                var c = document.getElementById(this.origam.zones[i].name);
                var ctx = c.getContext("2d");
                ctx.drawImage(img, 0, 0);
            });

            //todo: function showBoth
            var cr = document.getElementById('printrecto');
            var cc = document.getElementById('printverso');
            cr.style.width = '30%';
            cr.style.height = '100%';
            cc.style.width = '30%';
            cc.style.height = '100%';

            // blur begin //todo: blur function
            let recto_blur = new cv.Mat(); //todo: rename blur_
            let verso_blur = new cv.Mat();
            let blur_size = 51 //this.recto.rows / 10 //todo: force uneven or it crashes
            let ksize = new cv.Size(blur_size, blur_size); //todo: depends on im size
            cv.GaussianBlur(this.recto, recto_blur, ksize, 0, 0, cv.BORDER_DEFAULT);
            cv.GaussianBlur(this.verso, verso_blur, ksize, 0, 0, cv.BORDER_DEFAULT);
            // blur end

            // todo: resize (downsize) also
            cv.imshow('printrecto', recto_blur)
            cv.imshow('printverso', verso_blur)
            this.generated = true;
            // window.scrollTo(0, document.body.scrollHeight);
            recto_blur.delete();
            verso_blur.delete();

            /////////////////////////

        },
        Tf3(aff) {
            let tf = cv.Mat.eye(3, 3, cv.CV_64F);
            let angle = aff.r / 180 * Math.PI
            // todo: if aff.a is undefined, a=1,
            // todo: if aff.r is undefined, r=0,
            // todo: if aff.t is undefined, t=[0,0]
            let a = aff.a
            tf.doublePtr(0, 0)[0] = a * Math.cos(angle)
            tf.doublePtr(1, 0)[0] = a * Math.sin(angle)
            tf.doublePtr(0, 1)[0] = -a * Math.sin(angle)
            tf.doublePtr(1, 1)[0] = a * Math.cos(angle)
            tf.doublePtr(0, 2)[0] = aff.t.x
            tf.doublePtr(1, 2)[0] = aff.t.y
            return tf

        },
        download(canvaId) {
            var link = document.createElement('a');
            link.download = canvaId + '.png';
            link.href = document.getElementById(canvaId).toDataURL()
            link.click();
        }

    }

}
</script>

<style>
/* .buy_button {
    display: block;
} */

canvas {
    margin: 2%;
    border: 5px solid black;
}
</style>