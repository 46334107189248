<script setup>
// todo: read vue3 scrip setup docs and put all in here
import { ref } from 'vue'

// defineProps({
//     segs: Array,
//     shapeName: String
// })

// const emit = defineEmits(['myEvent'])

// function onClick() {
//     this.clicked = true;
//     emit('myEvent')
//     console.log('CLICK!')
// }

const count = ref(0)

</script>

<script>
export default {
    props: {
        shapeName: String,
        points: Object,
        seg_ids: Array
    },
    mounted() {
        var c = document.getElementById(this.shapeName);
        var ctx = c.getContext("2d");
        this.vueCanvas = ctx;
        //todo: try add these in dom with @...:
        document.addEventListener('keydown', ev => {
            if (ev.repeat) return
            this.zoom = 10;
        })
        document.addEventListener('keyup', e => {
            this.zoom = 1;
        })
        c.addEventListener('mousemove', e => {
            if (this.clicked) {
                return
            }
            this.cX = e.offsetX
            this.cY = e.offsetY
            this.showShape(ctx, this.cX, this.cY, 1, 'black')
        })
        c.addEventListener("wheel", event => {
            if (this.clicked) {
                return
            }
            const delta = Math.sign(event.deltaY);
            let char_width = 1 //todo: dataprep
            let da = 1 / char_width
            this.affine.a += da * delta * this.zoom
            if (this.affine.a < 0) {
                this.affine.a = 0
            }
            this.showShape(ctx, this.cX, this.cY, 1, 'black')
        });
        c.addEventListener('mouseenter', e => {
            // todo: canvas style size such as it fits in window?
            if (this.clicked) {
                return
            }
            document.body.style.overflow = 'hidden';
        })
        c.addEventListener('mouseleave', e => {
            if (this.clicked) {
                return
            }
            document.body.style.overflow = '';
        })
    },
    data() {
        return {
            url: null,
            img0: null,
            imgHW: [0, 0], //could avoid
            affine: {
                r: 0,
                a: 1,
                t: { x: 0, y: 0 }
            },
            cX: 0.,//put in setup?
            cY: 0.,
            zoom: 1,
            scrollXY: [0, 0],
            clicked: false
        }
    },
    emits: (['myEvent', 'remove']),
    methods: {
        reset() {
            this.clicked = false
            this.vueCanvas.drawImage(this.img0, 0, 0);
            this.$emit('remove')
        },
        onFileChangex(e) {
            this.clicked = false
            const file = e.target.files[0];
            this.url = URL.createObjectURL(file);
            ////////////////////////////
            var img = new Image();
            var c = document.getElementById(this.shapeName);
            var ctx = c.getContext("2d");
            let aff = this.affine //other way to do this?
            // shorter: var ctx = $("canvas")[0].getContext("2d") 
            let thisidx = document.getElementById('c' + this.shapeName)
            img.onload = function () {
                c.height = img.height
                c.width = img.width
                c.style.width = '75%';
                c.style.height = '100%';
                ctx.drawImage(img, 0, 0);
                aff.a = c.width / 50
                thisidx.scrollIntoView({ behavior: 'smooth', block: 'center' })

            };
            img.src = this.url
            this.img0 = img
        },
        drawLine(context, x1, y1, x2, y2, w, color) {
            context.beginPath();
            context.strokeStyle = color;
            context.lineWidth = w;
            context.moveTo(x1, y1);
            context.lineTo(x2, y2);
            context.stroke();
            context.closePath();
        },
        showPoint(ctx, x, y) {
            ctx.drawImage(this.img0, 0, 0);
            // let r_draw_im = ctx.canvas.clientWidth / ctx.canvas.width
            ctx.fillRect(x, y, 5 / r_draw_im, 5 / r_draw_im)
        },
        showShape(ctx, x, y, w, color) {
            if (!this.url) return
            let r_draw_im = ctx.canvas.clientWidth / ctx.canvas.width
            let iX = x / r_draw_im
            let iY = y / r_draw_im
            ctx.drawImage(this.img0, 0, 0);
            // let r_draw_im = ctx.canvas.clientWidth / ctx.canvas.width
            let a = this.affine.a
            this.seg_ids.forEach(seg_id => {
                let p0 = this.points[seg_id.p0]
                let p1 = this.points[seg_id.p1]
                this.drawLine(ctx,
                    iX + a * p0.x, iY - a * p0.y,
                    iX + a * p1.x, iY - a * p1.y, w / r_draw_im, color)
            });
            this.affine.t.x = iX
            this.affine.t.y = iY
        },
        onClick() {
            if (this.clicked) {
                return
            }
            this.clicked = true;
            document.body.style.overflow = '';

            this.showShape(this.vueCanvas, this.cX, this.cY, 2, 'green')


            var img1 = new Image();
            var c = document.getElementById(this.shapeName);
            img1.src = c.toDataURL();

            this.$emit('myEvent', this.affine, this.img0, img1) //todo: could emit img1 with borders also
        }
    }
}
</script>

<template>
    <div class="card" :id="'c' + shapeName">
        <h2>{{ shapeName }}</h2>
        <canvas
            :id="shapeName"
            width="0"
            height="0"
            @click="onClick"
            :class="{ nocursor: !clicked }"
        ></canvas>
        <div class="chosepic">
            <label for="inputpic">Choose a picture:</label>
            <input v-on:change="onFileChangex" type="file" id="inputpic" accept=".jpg, .jpeg, .png" />
        </div>
        <button type="button" @click="reset" :hidden="clicked == false">reset position</button>
    </div>
</template>

<style scoped>
a {
    color: #42b983;
}
canvas {
    border: 0px solid black;
}
.nocursor {
    cursor: none;
}

.card {
    /* border: black; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: 75%;
    margin: auto;
    background-color: lightgray;
}
</style>